/*
 *
 * Estas librerias estan presentes tanto en la website como en el dashboard
 *
 */

//Librerias necesarias


import vueTopprogress from 'vue-top-progress';
// import '@fortawesome/fontawesome-free/js/all.js';


//Funcion para añadirlas a Vue
function fire(Vue){
	

	
	//Loading bar
	Vue.use(vueTopprogress);
	//vue2Editor
	
}



// Install by default if using the script tag
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default fire;
