<template lang="html">
  <div id="about-us-page">

    <section class="main-section">
      <i class="bg"></i>

      <div class="container oversized-container">
        <div class="row">
          <div class="offset-lg-6 col-lg-6 col-xl-5 col-contant">
            <h1 class="mb-3 title">Service Concierge</h1>

            <div class="row">
              <div class="col-lg-12 col-info">
                <h5 class="subtitle">REFINISH STAINLESS STEEL</h5>

                <div class="b-row">
                  <h6 class="brand">AUDEMARS PIGUET</h6>
                  <p class="price">
                    $9,000 MXN
                  </p>
                </div>

                <div class="b-row">
                  <h6 class="brand">PATEK PHILIPPE</h6>
                  <p class="price">
                    $9,000 MXN
                  </p>
                </div>

                <div class="b-row">
                  <h6 class="brand">ROLEX</h6>
                  <p class="price">
                    $6,000 MXN
                  </p>
                </div>
              </div>

              <div class="col-lg-12 mt-4 col-info">
                <h5 class="subtitle">REFINISH GOLD & PLATINUM</h5>

                <div class="b-row">
                  <h6 class="brand">AUDEMARS PIGUET</h6>
                  <p class="price">
                    $12,000 MXN
                  </p>
                </div>

                <div class="b-row">
                  <h6 class="brand">PATEK PHILIPPE</h6>
                  <p class="price">
                    $12,000 MXN
                  </p>
                </div>

                <div class="b-row">
                  <h6 class="brand">ROLEX</h6>
                  <p class="price">
                    $11,000 MXN
                  </p>
                </div>
              </div>

              <div class="col-lg-12 mt-4 col-info">
                <h5 class="subtitle">SERVICE</h5>

                <p class="p-lg">
                  QUOTE FOR FREE , FOR ALL KIND OF MECHANICAL WATCHES.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
