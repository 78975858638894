<template lang="html">
  <b-form id="products-results-page" @submit="onSubmit">

    <section class="products-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 box-breadcrumb-s1">
            <!-- <span>Home</span>
            <router-link to="">Products</router-link> -->
          </div>
        </div>

        <div class="row">
          <!-- Mobile filters -->
          <!-- <div class="col-12 col-filters-mobile">
            <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
            <a class="btn-filters" @click="showFilters = !showFilters">
              <i class="fas fa-filter"></i><span>Ver filtros</span>
            </a>
          </div> -->
          <!--  -->

          <!-- Col filters -->
          <!-- <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
            <h4 class="d-lg-none _title">
              <span><strong>Filtrar</strong></span>
              <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
            </h4>

            <div class="filters-container">
              <div class="box-filters">
                <h6 class="group-f-title">Brands</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="formSearch.category"
                    :aria-describedby="ariaDescribedby"
                    name="fil-0">
                    <b-form-checkbox
                      :value="m.id"
                      v-for="(m, mInx) in marcas"
                      :key="'mInx-'+mInx">
                      {{ m.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="box-filters">
                <h6 class="group-f-title">Categories</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="formSearch.shape"
                    :aria-describedby="ariaDescribedby"
                    name="fil-1">
                    <b-form-checkbox
                      :value="c.id"
                      v-for="(c, cInx) in categorias"
                      :key="'cInx-'+cInx">
                      {{ c.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="box-filters">
                <h6 class="group-f-title">Materials</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="formSearch.brand"
                    :aria-describedby="ariaDescribedby"
                    name="fil-2">
                    <b-form-checkbox
                      :value="b.id"
                      v-for="(b, bInx) in materiales"
                      :key="'bInx-'+bInx">
                      {{ b.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>

            </div>
          </div> -->
          <!--  -->

          <!-- Col filter v2 -->
          <div class="col-12 col-display">
            <a class="btn-display" v-if="showAs == 'blocks'" @click="showAs = 'lists'">
              <i class="far fa-bars"></i>
            </a>

            <a class="btn-display" v-if="showAs == 'lists'" @click="showAs = 'blocks'">
              <i class="fas fa-th-large"></i>
            </a>
          </div>
          <!--  -->

          <!-- Col products -->
          <div class="col-lg-12 col-results">
            <div class="row justify-content-center" v-if="showAs == 'lists'">
              <div class="col-12 col-xl-10 box-product-sample-s2" v-for="(p, plInx) in products" :key="'plInx-'+plInx">
                <div class="box-link">
                  <router-link class="box-link" :to="'/producto/'+p.id" style="text-decoration: none;">
                      <div class="col col-image">
                        <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                          <img class="d-none d-lg-block" src="public/images/shared/empty.png">
                          <img class="d-lg-none" src="public/images/shared/product.png">
                        </div>
                      </div>

                      <div class="col col-info">
                        <div class="box-bubbles">
                          <!-- <span class="bubble bg-1" v-if="p.this_just_in == 1">This just in</span> -->
                          <span class="bubble bg-2" v-if="p.recently_sold == 1">Sold</span>
                          <span class="bubble bg-3" v-if="p.new_arrival == 1">New arrivals</span>
                          <span class="bubble bg-4" v-if="p.sale_pending == 1">Sale pending</span>
                          <span class="bubble bg-5" v-if="p.in_stock == 1">In stock</span>
                        </div>

                        <h6 class="brand">{{ p.brand }}</h6>
                        <h6 class="name">{{ p.name }}</h6>

                        <div class="d-none d-lg-block descr">
                          <p><strong>Reference: </strong> {{p.reference_number}}</p>
                          <!-- <p><strong>Materials: </strong> Oro amarillo</p> -->
                        </div>
                        <div class="descr">
                          <p v-html="p.description">

                          </p>
                        </div>

                        <h6 class="price" v-if="p.recently_sold != 1"><span>$</span> {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(p.price) }} <span>USD</span></h6>
                      </div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="row" v-if="showAs == 'blocks'">
              <!-- <div class="col-12 col-title">
                <h4 class="title-s1">Products</h4>
              </div> -->

              <div class="col-sm-6 col-lg-4 col-xl-3 box-product-sample-s1" v-for="(p, pInx) in products" :key="'pInx-'+pInx">
                <router-link class="box-link" :to="'/producto/'+p.id">
                  <div class="box-image">
                    <img class="placed-backg" src="public/images/shared/product.png" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">

                    <div class="box-bubbles">
                      <!-- <span class="bubble bg-1" v-if="p.this_just_in == 1">This just in</span> -->
                      <span class="bubble bg-2" v-if="p.recently_sold == 1">Sold</span>
                      <span class="bubble bg-3" v-if="p.new_arrival == 1">New arrivals</span>
                      <span class="bubble bg-4" v-if="p.sale_pending == 1">Sale pending</span>
                      <span class="bubble bg-5" v-if="p.in_stock == 1">In stock</span>
                    </div>
                  </div>

                  <div class="box-descr">
                    <h6 class="brand">{{ p.brand }}</h6>

                    <h6 class="name">{{ p.name }}</h6>
                    <!-- <h6 class="ref">REF. {{ p.sku }}</h6> -->
                    <!-- <h6 class="included">BOX & PAPERS</h6> -->
                    <!-- <div class="descr">
                      <p>Producto, descripción, información extra y más va aquí</p>
                    </div> -->

                    <h6 class="price" v-if="p.recently_sold != 1"><span>$</span> {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(p.price) }} <span>USD</span></h6>

                    <!-- <p class="mt-3">
                      <span class="btn-more">DISCOVER</span>
                    </p> -->
                  </div>
                </router-link>
              </div>
            </div>

            <!-- <div class="row mt-4" v-if="pagination.total_products > 6">
              <div class="col-12 text-center">
                <div class="d-inline-block mx-0 col-pagination-sample-1">
                  <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                </div>
              </div>
            </div> -->
          </div>
          <!--  -->

        </div>
      </div>
    </section>

  </b-form>
</template>

<script>
export default {
  data() {
    return {
      showFilters: false,
      showAs: 'blocks',

      formSearch: {
        keywords: null,
        order: 1,
        category: [],
        shape: [],
        price: null,
        discount: null,
      },

      products: [],

      pagination:{
        currentpage: 1,
        total: 1,
        total_products: 16,
        number: 10
      },
      id:null,
    }
  },
    watch:{
        '$route.params.id_cat':function(){

            this.id = this.$route.params.id_cat;
            this.getRow();

        }
    },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },

    onSubmit(event) {
      event.preventDefault();
    },
    getRow(){
        // axios.get("https://new.legacytimepieces.com.mx/api/products/"+this.id).then((response)=>{
        axios.get(tools.url("/api/products/"+this.id)).then((response)=>{
			    	this.products = response.data;
			  }).catch((error)=>{

			  });
    }
  },

  mounted() {

				this.id = this.$route.params.id_cat;
				this.getRow();


  }
}
</script>
