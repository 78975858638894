<template lang="html">
  <div id="home-page">

    <section class="banner-section" data-aos="fade">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.pc" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.movil" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container-fluid multi-banners-section">
      <div class="row no-gutters">
        <div class="col-lg-6 col-banner" v-if="bannerssecondary_one">
          <a href="#">
            <img :src="bannerssecondary_one.imageUrl" alt="">
          </a>
        </div>

        <div class="col-lg-6 col-banner" v-if="bannerssecondary_one">
          <a href="#">
            <img :src="bannerssecondary_two.imageUrl" alt="">
          </a>
        </div>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="d-block mb-1 mb-md-3">
        <h2 class="title-s1">New arrival</h2>
      </div>

      <div class="box-products">
        <swiper class="swiper swiper-s1" :options="productsOptions">
          <swiper-slide v-for="(p, phInx) in thisjustin" :key="'phInx-'+phInx">
            <div class="col-12 px-0 box-product-sample-s1">
              <router-link class="box-link" :to="'/producto/'+p.id">
                <div class="box-image">
                  <img class="placed-backg" src="public/images/shared/product.png" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">

                  <div class="box-bubbles">
                    <!-- <span class="bubble bg-1" v-if="p.this_just_in == 1">This just in</span> -->
                    <span class="bubble bg-2" v-if="p.recently_sold == 1">Sold</span>
                    <span class="bubble bg-3" v-if="p.new_arrival == 1">New arrivals</span>
                    <span class="bubble bg-4" v-if="p.sale_pending == 1">Sale pending</span>
                    <span class="bubble bg-5" v-if="p.in_stock == 1">In stock</span>
                  </div>
                </div>

                <div class="box-descr">
                  <h6 class="brand">{{p.brand}}</h6>

                  <h6 class="name">{{ p.name }}</h6>
                  <!-- <h6 class="ref">REF. {{ p.sku }}</h6> -->
                  <!-- <h6 class="included">BOX & PAPERS</h6> -->
                  <!-- <div class="descr">
                    <p>Producto, descripción...</p>
                  </div> -->

                  <h6 class="price"><span>$</span>{{ Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(p.price) }} <span>USD</span></h6>

                  <!-- <p class="mt-3">
                    <span class="btn-more">DISCOVER</span>
                  </p> -->
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>

    <!-- <section class="container oversized-container banner-lg-section">
      <div class="box-banner">
        <router-link to="/contacto">
          <img src="public/images/pages/home/banner-b-1.jpg">
        </router-link>
      </div>
    </section> -->

    <!-- <section class="container oversized-container brands-section">
      <div class="row">
        <div class="col-12 mb-1 mb-md-3 col-title">
          <h2 class="title-s1">Nuestras marcas</h2>
        </div>

        <div class="col-12 col-brands">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(b, brhInx) in brands" :key="'brhInx-'+brhInx">
              <div class="box-brand">
                <a :href="b.link">
                  <div class="inside">
                    <img :src="b.imageUrl">
                  </div>
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section> -->

    <section class="bg-text-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-xl-7 col-text">
            <p>
              WE PROUDLY HOUSE THE MOST EXTENSIVE SELECTION OF LUXURY WATCHES, INCLUDING BOTH PRE-OWNED AND NEW TIMEPIECES. EACH WATCH IS METICULOUSLY AUTHENTICATED AND UPHELD TO COLLECTOR QUALITY STANDARDS, ENSURING YOU HAVE ACCESS TO THE FINEST SELECTION AVAILABLE.
            </p>
          </div>

          <div class="col-lg-4 col-xl-3 offset-xl-1 col-logo">
            <img src="public/images/iso-transparent.png">
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="d-block mb-1 mb-md-3">
        <h2 class="title-s1">RECENTLY SOLD</h2>
      </div>

      <div class="box-products">
        <swiper class="swiper swiper-s2" :options="productsOptions">
          <swiper-slide v-for="(p, phInx) in recentlysold" :key="'phInx-'+phInx">
            <div class="col-12 px-0 box-product-sample-s1">
              <router-link class="box-link" :to="'/producto/'+p.id">
                <div class="box-image">
                  <img class="placed-backg" src="public/images/shared/product.png" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">

                  <div class="box-bubbles">
                    <!-- <span class="bubble bg-1" v-if="p.this_just_in == 1">This just in</span> -->
                    <span class="bubble bg-2" v-if="p.recently_sold == 1">Sold</span>
                    <span class="bubble bg-3" v-if="p.new_arrival == 1">New arrivals</span>
                    <span class="bubble bg-4" v-if="p.sale_pending == 1">Sale pending</span>
                    <span class="bubble bg-5" v-if="p.in_stock == 1">In stock</span>
                  </div>
                </div>

                <div class="box-descr">
                  <h6 class="brand">{{ p.brand }}</h6>

                  <h6 class="name">{{ p.name }}</h6>
                  <!-- <h6 class="ref">REF. {{ p.sku }}</h6> -->
                  <!-- <h6 class="included">BOX & PAPERS</h6> -->
                  <!-- <div class="descr">
                    <p>Producto, descripción, información extra y más va aquí</p>
                  </div> -->

                  <!-- <h6 class="price">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(p.price) }} USD</h6> -->

                  <!-- <p class="mt-3">
                    <span class="btn-more bg-gold">EXPLORE</span>
                  </p> -->
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>

    <section class="container oversized-container extra-info-section">
      <div class="row">
        <div class="col-lg-4 col-info">
          <h6>GLOBAL INVENTORY</h6>

          <p>
            We own every watch we sell, which allows us to set superior standards worldwide. With offices in Mexico City and contacts on every continent, our selection is constantly changing with hundreds of luxury watches available at all times.
          </p>
        </div>

        <div class="col-lg-4 col-info">
          <h6>A TEAM OF EXPERTS</h6>

          <p>
            Our in-house team of watchmakers and restorers are among the finest in the industry. From routine maintenance to severe damage, our team provides comprehensive support with factory parts for first-class service.
          </p>
        </div>

        <div class="col-lg-4 col-info">
          <h6>IMMERSE IN LUXURY TIMEPIECES WITH US</h6>

          <p>
            Our team is deeply passionate about luxury watches and is excited to share this passion with you. We'd love to expertly guide you in acquiring the most exceptional timepiece.
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: {

      },
      recentlysold:[],
      thisjustin:[],

      /*brands: [
        { imageUrl: 'public/images/pages/home/brand-1.jpg' },
        { imageUrl: 'public/images/pages/home/brand-2.svg' },
        { imageUrl: 'public/images/pages/home/brand-4.svg' },
        { imageUrl: 'public/images/pages/home/brand-5.jpg' },
        { imageUrl: 'public/images/pages/home/brand-3.jpg' },
        { imageUrl: 'public/images/pages/home/brand-6.jpg' },
        { imageUrl: 'public/images/pages/home/brand-8.jpg' },
        { imageUrl: 'public/images/pages/home/brand-9.jpg' },
        { imageUrl: 'public/images/pages/home/brand-10.jpg' },
        { imageUrl: 'public/images/pages/home/brand-7.jpg' },
      ],*/

      // == Carousel options ==
      bannersOptions: {
        effect: 'fade',
        loop: true,
        speed: 700,

        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      brandsOptions: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 20,

        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },

        breakpoints: {
          1300: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }
      },

      productsOptions: {
        slidesPerView: 4,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },

        breakpoints: {
          1400: {
            slidesPerView: 4,
          },
          1100: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },
      bannerssecondary_one:null,
      bannerssecondary_two:null,
      // == ==
    }

  },
  methods:{
    getContent(){
      // axios.get("https://new.legacytimepieces.com.mx/api/home").then((response)=>{
      axios.get(tools.url("/api/home")).then((response)=>{
          this.recentlysold = response.data.recentlysold;
          this.thisjustin = response.data.thisjustin;
          this.banners = response.data.banners;
          this.bannerssecondary_one = response.data.bannerssecondary_one;
          this.bannerssecondary_two = response.data.bannerssecondary_two;
        }).catch((error)=>{
        });
    },

  },
  mounted(){
      this.getContent();
  }

}
</script>
