<template lang="html">
  <header id="header">
    <div class="header-content">

      <!-- Header top -->
      <div class="header-top">
        <div class="b-1">
          <h6 class="txt">The only Luxury Timepiece Experience you need! Based in Mexico City <img class="ic-flag" src="public/images/icon-mx-flag.svg"> & shipping worldwide <img class="ic-world" src="public/images/icon-world.svg"> since 2004.</h6>

          <!-- <div class="imgs">
            <img class="mr-4" src="public/images/shared/i-shield.png">
            <img src="public/images/shared/i-american-express.png">
            <img src="public/images/shared/i-mastercard.png">
            <img src="public/images/shared/i-visa.png">
          </div> -->
        </div>

        <!-- <div class="b-2">
          <h6 class="txt">o con deposito o transferencia bancaria</h6>

          <div class="imgs">
            <img src="public/images/shared/i-card.png">
          </div>
        </div> -->
      </div>
      <!--  -->

      <!-- Header menu -->
      <div class="header-menu">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container oversized-container">
            <div class="box-logo">
              <b-navbar-brand to="/">
                <img src="public/images/logo.png">
              </b-navbar-brand>

              <div class="box-extra">
                <a class="t-150 btn-search" @click="$refs['modal-search'].show()"><i class="far fa-search"></i></a>
              </div>
            </div>

            <b-navbar-toggle target="nav-collapse">
              <i class="fal fa-bars"></i>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mb-2 mb-lg-0 d-none d-lg-flex">
                <!-- <b-nav-item class="simple-item d-lg-none" to="/">Inicio</b-nav-item> -->
                <!-- <li class="nav-item simple-item">
  								<a class="nav-link" @click="isPActive = !isPActive">Relojes <i class="fas fa-caret-down"></i></a>
  							</li> -->
                <b-nav-item class="simple-item" :to="'/productos/'+bran.id" v-for="(bran,indx) in brandsOpcs" :key="indx">{{bran.name}}</b-nav-item>
                <!-- <b-nav-item class="simple-item" :to="'/productos/'+cat.id" v-for="(cat,indx) in categoriesOpcs" :key="indx">{{cat.name}}</b-nav-item> -->

                <b-nav-item class="simple-item" to="/sell-upgrade">Sell <span style="font-family: Barlow; font-weight: 400">&</span> Upgrade</b-nav-item>
                <!-- <b-nav-item class="simple-item" to="/productos/thisjustin">This just in</b-nav-item> -->
                <b-nav-item class="simple-item" to="/productos/thisjustin">New arrivals</b-nav-item>
                <b-nav-item class="simple-item" to="/productos/recentlysold">Rencently sold</b-nav-item>
                <b-nav-item class="simple-item" to="/productos">Full collection</b-nav-item>
                <b-nav-item class="simple-item" to="/nosotros">Service Concierge</b-nav-item>
                <b-nav-item class="simple-item" to="/nosotros">Straps and Accesories</b-nav-item>
              </b-navbar-nav>

              <b-navbar-nav class="mb-2 mb-lg-0 pt-3 d-lg-none">
                <b-nav-item class="simple-item" :to="'/productos/'+bran.id" v-for="(bran,indx) in brandsOpcs" :key="indx">{{bran.name}}</b-nav-item>

                <!-- <b-nav-item class="simple-item" to="">Upgrade your timepiece</b-nav-item>
                <b-nav-item class="simple-item" to="">Sell your timepiece</b-nav-item> -->

                <li class="nav-item simple-item special-item">
                  <router-link class="nav-link" to="/sell-upgrade">SELL <span style="font-family: Barlow; font-weight: 400">&</span> UPGRADE</router-link>
                </li>
                <!-- <b-nav-item class="simple-item" to="/thisjustin">This just in</b-nav-item> -->
                <b-nav-item class="simple-item" to="/productos/thisjustin">New arrivals</b-nav-item>
                <b-nav-item class="simple-item" to="/productos/recentlysold">Rencently sold</b-nav-item>
                <b-nav-item class="simple-item" to="/productos">Full Collection</b-nav-item>
                <b-nav-item class="simple-item" to="/nosotros">Service Concierge</b-nav-item>
                <b-nav-item class="simple-item" to="/nosotros">Straps and Accesories</b-nav-item>


                <!-- <b-nav-item class="simple-item d-lg-none" to="/contacto">Contacto</b-nav-item> -->

                <!-- <b-nav-item class="cart-item d-none d-lg-flex" to="/contacto">
                  <div>
                    <i class="fal fa-envelope"></i>
                  </div>
                </b-nav-item>
                <b-nav-item class="cart-item" to="/cart">
                  <div>
                    <i class="fal fa-shopping-cart icon"></i> <span class="label">Mi carrito</span> <i class="num">0</i>
                  </div>
                </b-nav-item> -->

                <!-- <b-nav-item-dropdown class="user-item" right>
                  <template #button-content>
                    <span><i class="fal fa-user icon"></i> <span class="label">Mi cuenta</span> </span>
                  </template>
                  <b-dropdown-item to="/login">Mi cuenta</b-dropdown-item>
                  <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
                </b-nav-item-dropdown> -->
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Header search -->
      <!-- <div class="header-search">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-lg-8 mx-auto col-right">
              <div class="content">
                <b-form inline @submit="onSubmitSearch">
                  <b-form-input
                    v-model="formSearch.keywords"
                    type="text"
                    placeholder="Buscar productos..."
                    required
                  ></b-form-input>
                  <b-button type="submit" class="t-250 btn-search">BUSCAR</b-button>
                </b-form>

              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--  -->

      <!-- Products menu -->
      <div class="t-250 products-menu" v-bind:class="{ active: isPActive }">
        <div class="menu-bg" @click="isPActive = false"></div>

        <div class="tablist" role="tablist">
          <div class="t-250 box-title" @click="isPActive = false">
            <h4>PRODUCTOS</h4>
            <br>
            <!-- <h5>Marcas</h5> -->
            <i class="fas fa-chevron-left icon"></i>
          </div>

          <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
            <b-card-header header-tag="header" role="tab">
              <div v-if="category.subcategories.length" class="btn-toggle">
                <router-link :to="'/productos/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
                <span class="fake-toggle" v-b-toggle="'accordion-'+pcatIndex"></span>
              </div>
              <router-link v-else class="btn-toggle-fake" :to="'/productos/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
            </b-card-header>

            <b-collapse v-if="category.subcategories.length" :id="'accordion-'+pcatIndex" :visible="category.showSubs" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ul class="ul-sub-options">
                  <li v-for="subcategory in category.subcategories">
                    <router-link class="t-250" :to="'/productos/'+$root._converToURL(category.name, category.id)+'/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <!--  -->

      <!-- Search modal -->
  		<b-modal ref="modal-search" modal-class="modal-search-e" size="lg" title="" @hidden="form.keywords = null" hide-footer no-close-on-esc no-close-on-backdrop>
        <!-- NOTA: form.keywords será null siempre al cerrar el modal, esto por usar el evento @hidden -->

        <b-form @submit="onSubmitSearch">
          <b-form-group>
            <b-form-input
              v-model="formSearch.keywords"
              type="text"
              placeholder="Search"
              size="lg"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" class="btn-search"><i class="far fa-search"></i></b-button>
        </b-form>
      </b-modal>
  		<!--  -->

    </div>
  </header>
</template>

<script>
export default {
  data(){
    return{
      brandsOpcs:[],
      categoriesOpcs:[],

      formSearch: {
        keywords: null
      },

      isPActive: false,

      categories:[
        { id: 1, name: 'Marcas', showSubs: true, subcategories: [
            { id: 2, name: 'Rolex' },
            { id: 2, name: 'Omega' },
            { id: 2, name: 'Audemars Piguet' },
            { id: 2, name: 'Patek Philippe' },
            { id: 2, name: 'Longines' },
            { id: 2, name: 'Panerai' },
            { id: 2, name: 'IWC' },
            { id: 2, name: 'Seiko' },
            { id: 2, name: 'Raymond Weil' },
            { id: 2, name: 'Rado' },
            { id: 2, name: 'Jaeger-LeCoultre' },
            { id: 2, name: 'Richard Mille' },
            { id: 2, name: 'Cartier' },
            { id: 2, name: 'TAG Heuer' },
            { id: 2, name: 'Hublot' },
            { id: 2, name: 'Breitling' },
            { id: 2, name: 'Bulgari' },
            { id: 2, name: 'Montblanc' },
            { id: 2, name: 'Mido' },
            { id: 2, name: 'Corum' },
            { id: 2, name: 'Tudor' },
            { id: 2, name: 'Tissot' },
            { id: 2, name: 'Baume & Mercier' },
            { id: 2, name: 'Chopard' },
          ]
        },

        { id: 2, name: 'Categorías', showSubs: false, subcategories: [
            { id: 3, name: 'Relojes de caballero / Relojes de dama' },
            { id: 3, name: 'Relojes nuevos / Relojes de segunda mano' },
            { id: 3, name: 'Relojes mecánicos / Relojes automáticos' },
            { id: 3, name: 'Relojes vintage' },
            { id: 3, name: 'Cronógrafos' },
            { id: 3, name: 'Relojes de buceo' },
            { id: 3, name: 'Relojes de piloto' },
            { id: 3, name: 'Relojes vintage' },
            { id: 3, name: 'Relojes militares' },
            { id: 3, name: 'Relojes suizos' },
            { id: 3, name: 'Relojes baratos' },
            { id: 3, name: 'Relojes populares' },
            { id: 3, name: 'Pulseras / Piezas y accesorios' },
          ]
        },
      ],
    }
  },

  methods: {
    onSubmitSearch(event) {
      event.preventDefault();
			this.$router.push({path: '/productos', query: {keywords:this.formSearch.keywords}});
      this.$refs['modal-search'].hide();
    },
    getBrands(){
        axios.get("https://new.legacytimepieces.com.mx/api/brands").then((response)=>{
        // axios.get(tools.url("/api/brands")).then((response)=>{
          this.brandsOpcs=response.data;
        }).catch((error)=>{
        });
    },
    getCategoriesOpcs(){
        // axios.get("https://new.legacytimepieces.com.mx/api/categories").then((response)=>{
        axios.get(tools.url("/api/categories")).then((response)=>{
          this.categoriesOpcs=response.data;
        }).catch((error)=>{
        });
    },
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;
    }
  },
  mounted(){
      this.getBrands();
      this.getCategoriesOpcs();
  }
}
</script>
