<template lang="html">
  <div id="sell-upgrade-page">

    <section class="main-section">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-top">
            <h1 class="title-s1">Sell</h1>

            <div class="row">
              <div class="col-lg-6 mx-auto">
                <h5 class="text-1">
                  WE ARE ALWAYS HAPPY TO BUY
                  YOUR LUXURY TIMEPIECE, LET
                  US KNOW IF YOU HAVE
                  SOMETHING FOR SALE.
                </h5>
              </div>
            </div>

            <div class="row box-steps">
              <div class="col-12 col-line">
                <hr />
              </div>

              <div class="col-md-6 col-lg-3 col-step">
                <p>
                  <span class="number">1</span>
                </p>

                <h5 class="text">SEND US THE MODEL AND BRAND OF YOUR TIME PIECE.</h5>
              </div>

              <div class="col-md-6 col-lg-3 col-step">
                <p>
                  <span class="number">2</span>
                </p>

                <h5 class="text">SEND US PICTURES AND IMPORTANT INFORMATION ABOUT YOUR TIMEPIECE.</h5>
              </div>

              <div class="col-md-6 col-lg-3 col-step">
                <p>
                  <span class="number">3</span>
                </p>

                <h5 class="text">RECEIVE A QUOTE FOR YOUR TIMEPIECE AND BOOK YOUR APPOINTMENT FOR AN INSPECTION.</h5>
              </div>

              <div class="col-md-6 col-lg-3 col-step">
                <p>
                  <span class="number">4</span>
                </p>

                <h5 class="text">ACCEPT YOUR QUOTE AND GET PAID INMEDIATLY.</h5>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-6 col-info">
            <h5 class="title">¿Tienes preguntas?<br />Envíanos un mensaje o comunicate con nosotros.</h5>

            <h5 class="mt-3 subtitle">Dirección Matriz</h5>
            <p>
              Calle 1 #123<br />
              Guadalajara, Jalisco<br />
              México.
            </p>

            <h5 class="mt-3 subtitle">Teléfono</h5>
            <p>
              Tel. 55 323-4332-9438
            </p>

            <h5 class="mt-3 subtitle">Correo</h5>
            <p>
              contacto@necesitolentes.club
            </p>

            <h5 class="mt-3 subtitle">Síguenos</h5>
            <p>
              <a class="btn-network mr-1" href=""><i class="fab fa-instagram"></i></a>
              <a class="btn-network ml-1" href=""><i class="fab fa-twitter"></i></a>
              <a class="btn-network ml-1" href=""><i class="fab fa-facebook-f"></i></a>
              <a class="btn-network ml-1" href="#"><i class="fab fa-linkedin-in"></i></a>
            </p>
          </div> -->

          <div class="col-lg-6 col-form">
            <b-form @submit.prevent="onSubmit($event.target)">
              <b-form-group class="custom-form-group-s1" label="Name">
                <b-form-input type="text" size="sm" v-model="form.name" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="custom-form-group-s1" label="Brand">
                <b-form-input type="text" size="sm" v-model="form.brand" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="custom-form-group-s1" label="Material">
                <b-form-input type="text" size="sm" v-model="form.material" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="custom-form-group-s1" label="Image">
                <b-form-file
                  v-model="form.image"
                  placeholder=""
                  drop-placeholder="Drop image here..."
                  accept="image/jpeg, image/png"
                  name="image"
                ></b-form-file>
              </b-form-group>

              <b-form-group class="custom-form-group-s1" label="Description">
                <b-form-textarea
                  required
                  v-model="form.description"
                  size="sm"
                  placeholder=""
                  rows="4"
                  max-rows="4"
                ></b-form-textarea>
              </b-form-group>

              <p class="text-right">
                <b-button type="submit" class="btn-s1 outline-primary br-10">SEND</b-button>
              </p>
            </b-form>
          </div>

          <div class="col-12 mt-5 col-top">
            <h1 class="title-s1">Upgrade</h1>

            <div class="row">
              <div class="col-lg-6 mx-auto">
                <h5 class="text-1">
                  UPGRADE YOUR LUXURY
                  TIMEPIECE, LET US KNOW IF
                  YOU HAVE SOMETHING TO
                  TRADE.
                </h5>

                <p class="mt-3">
                  <img class="img-upgrade" src="public/images/upgrade.svg">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-right',
        appendToast: true
      })
    },

    onSubmit(form) {
      var data=tools.params(form,this.form);

      var apiURL = tools.url("/api/sellupgrade");
      //
      axios.post( apiURL,data ).then( (response) => {
        this.form = {
          name:null,
          brand:null,
          material:null,
          description:null,
         
          
        }
          alert('information saved correctly');
      })
      .catch( (error) => {
        alert('Error saving information');

      });
    },
  },
}
</script>
