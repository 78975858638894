<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title txt-black">Privacy Policy</h1>

    <p class="mb-2">
      Welcome to LEGACY TIME PIECES. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.legacytimepieces.com] and use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
    </p>

    <br />
    <h5>1. Information We Collect</h5>
    <p>
      We collect limited personal information from you to facilitate your purchase process and enhance your experience on our website. The types of information we may collect include:
    </p>
    <ol>
      <li>Name.</li>
      <li>Email address.</li>
      <li>Phone number.</li>
      <li>Shipping address.</li>
      <li>Payment information</li>
    </ol>

    <h5>2. How We Use Your Information</h5>
    <p>
      We use the information we collect from you for a variety of business purposes, including:
    </p>
    <ol>
      <li>To process transactions more quickly and efficiently.</li>
      <li>To send periodic emails regarding your order or other products and services.</li>
      <li>To personalize your experience on our site.</li>
      <li>To improve our website and services.</li>
    </ol>

    <br />
    <h5>3. Disclosure of Your Information</h5>
    <p>
      We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
    </p>

    <br />
    <h5>4. Protection of Your Information</h5>
    <p>
      We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. All transactions are processed through a gateway provider and are not stored or processed on our servers.
    </p>

    <br />
    <h5>5. Use of Cookies</h5>
    <p>
      Our website may use cookies to enhance the user experience. Your web browser places cookies on your hard drive for record-keeping purposes and sometimes to track information about them. You can choose to set your web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the website may not function properly.
    </p>

    <br />
    <h5>6. Changes to This Privacy Policy</h5>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy
    </p>

    <br />
    <p>
      Last Updated: [28/06/2024]
    </p>
  </div>
</template>

<script>
export default {
}
</script>
