<template lang="html">
  <div id="product-description-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <!-- <span>Home</span>
        <span class="line">/</span>
        <span>Products</span>
        <span class="line">/</span>
        <span>{{product.brand}}</span> -->
      </div>
    </section>

    <section class="container product-info-section">
      <div class="row">
        <div class="col-lg-6 align-self-start col-gallery">
          <div class="box-bubbles">
            <!-- <span class="bubble bg-1" v-if="product.this_just_in == 1">This just in</span> -->
            <span class="bubble bg-2" v-if="product.recently_sold == 1">Sold</span>
            <span class="bubble bg-3" v-if="product.new_arrival == 1">New arrivals</span>
            <span class="bubble bg-4" v-if="product.sale_pending == 1">Sale pending</span>
            <span class="bubble bg-5" v-if="product.in_stock == 1">In stock</span>
          </div>

          <swiper class="swiper swiper-s2 secondary" :options="galleryOptions">
            <swiper-slide v-for="(gal,indx) in product.images" :key="indx">
              <inner-image-zoom zoomType="hover" :src="gal.imageUrl" :zoomSrc="gal.imageUrl" zoomScale="0.6" />
              <!-- <div class="box-image" v-bind:style="{ backgroundImage: 'url('+gal.imageUrl+')' }">
                <img src="public/images/shared/product.png">
              </div> -->
            </swiper-slide>


            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>

        <div class="col-lg-6 col-info">
          <h1 class="p-name f-f-lbodoni">{{product.name}}</h1>
          <!-- <h3 class="p-price"><span class="discount">$1,900</span> <span>$1,650</span></h3> -->
          <h3 class="p-price f-f-lbodoni" v-if="product.recently_sold != 1"><span>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(product.price) }} USD</span></h3>
          <!-- <h6 class="mt-1 p-label">+ $2,580 MXN shipping with insurance to <u>México</u></h6> -->

          <ul class="txt-list-info">
            <li v-if="product.brand">
              <strong class="f-w-500" style="color: #7e7e7e !important;">Brand: </strong>
              <span style="color: #1d3d4a !important;">{{ product.brand }}</span>
            </li>

            <li v-if="product.reference_number">
              <strong class="f-w-500" style="color: #7e7e7e !important;">Reference: </strong>
              <span style="color: #1d3d4a !important;">{{ product.reference_number }}</span>
            </li>

            <li v-if="product.sku">
              <strong class="f-w-500" style="color: #7e7e7e !important;">Reference: </strong>
              <span style="color: #1d3d4a !important;">{{ product.sku }}</span>
            </li>

            <li v-if="product.materials">
              <strong class="f-w-500" style="color: #7e7e7e !important;">Materials: </strong>
              <span style="color: #1d3d4a !important;">{{ product.materials }}</span>
            </li>

            <li v-if="product.box == 1">
              <strong class="f-w-500">Box </strong>

            </li>
            <li v-if="product.papers == 1">
              <strong class="f-w-500">Certificate </strong>

            </li>
            <li>
              <strong class="f-w-500" style="color: #7e7e7e !important;">Condition: </strong>
              <strong class="f-w-500" v-if="product.used_new == 'Used'">Pre owned </strong>
              <strong class="f-w-500" v-if="product.used_new == 'New'">New </strong>
            </li>
            <li v-if="product.sizes">
              <strong class="f-w-500" style="color: #7e7e7e !important;">Diameter: </strong>
              <span style="color: #1d3d4a !important;">{{ product.sizes }}</span>
            </li>
            
            
            <li v-if="product.description">
              <strong class="f-w-500" style="color: #7e7e7e !important;">Description: </strong>
              <div class="b-descr" v-html="product.description" style="color: #1d3d4a !important;"></div>
            </li>

            
          </ul>
          <!-- <h6 class="mt-4 p-label f-f-lbodoni"><strong>Brand: </strong> {{product.brand}}</h6>
          <h6 class="mt-4 p-label f-f-lbodoni"><strong>Reference: </strong> {{product.reference_number}}</h6>
          <h6 class="mt-4 p-label f-f-lbodoni"><strong>Materials: </strong> {{product.materials}}</h6>

          <h6 class="mt-4 subtitle f-f-lbodoni">Description:</h6>
          <div class="box-descr f-f-lbodoni">
            <p>
              Reference: {{product.sku}}<br />
            </p>
            <br />

            <p v-html="product.description"></p>
          </div> -->

          <!-- <h6 class="mt-4 subtitle">Medidas:</h6>
          <div class="box-descr">
            <strong>Alto:</strong> 41mm | <strong>Ancho:</strong> 147mm | <strong>Largo:</strong> 145mm
          </div> -->

          <!-- <h6 class="mt-4 subtitle">Cantidad:</h6>
          <div class="col-quantity2 mt-2">
            <a class="form-control btn-q" @click="changeCantidad('-')">
              <i class="fas fa-minus"></i>
            </a>
            <b-form-input class="input-q" type="number" min="0" v-model="form.quantity" @keypress="isNumber($event)" @paste="noPaste" />
            <a class="form-control btn-q" @click="changeCantidad('+')">
              <i class="fas fa-plus"></i>
            </a>
          </div> -->

          <p class="mt-4 text-center text-lg-left" v-if="product.recently_sold != 1">
            <button class="btn btn-s1 primary px-5 w-100 btn-add" type="button" name="button" @click="toContact">MAKE AN OFFER</button>
            <button class="mt-3 btn btn-s1 gold px-5 w-100 btn-add" type="button" name="button">TRADE FOR THIS WATCH</button>
          </p>
          <p class="mt-4 text-center text-lg-left" v-else>
            <button class="btn btn-s1 primary px-5 w-100 btn-add" style="background-color: #7b7b7b;border-color: #7b7b7b;" type="button" name="button" @click="toContact">INQUIRE FOR THIS WATCH</button>


          </p>
        </div>
      </div>
    </section>

    <section class="container oversized-container related-products-section" v-if="false">
      <div class="d-block pb-4">
        <h4 class="title-s1">Similar models</h4>
        <hr class="mt-1" />
      </div>

      <swiper class="swiper" :options="productsOptions">
        <swiper-slide v-for="(p, pInx) in products" :key="'pInx-'+pInx">
          <div class="col-12 px-0 box-product-sample-s1">
            <router-link class="box-link" :to="'/producto/'+p.id">
              <!-- <span class="bubble" v-bind:class="{ 'bg-1' : p.tagbg == 1, 'bg-2' : p.tagbg == 2, 'bg-3' : p.tagbg == 3 }">{{ p.tag }}</span> -->
              <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                <img src="public/images/shared/product.png">
              </div>

              <div class="box-descr">
                <h6 class="brand">{{p.brand}}</h6>

                <h6 class="name">{{ p.name }}</h6>
                <!-- <h6 class="ref">REF. {{p.sku}}</h6> -->

                <!-- <div class="descr">
                  <p>Producto, descripción, información extra y más va aquí</p>
                </div> -->

                <!-- <h6 class="price">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(p.price) }} USD</h6> -->

                <!-- <p class="mt-3">
                  <span class="btn-more">DISCOVER</span>
                </p> -->
              </div>
            </router-link>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

  </div>
</template>

<script>
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
export default {
  components: {
    'inner-image-zoom': InnerImageZoom
  },

  data() {
    return {
      material: {},
      recubrimiento: {},

      // Materieles
      materiales: [],

      // Recubrimientos
      recubrimientos: [],

      products: [
        { imageUrl: 'public/images/pages/products/product-3.jpg', price: '275000', name: 'GRAND COMPLICATIONS', tag: 'New Arrival', tagbg: 1 },
        { imageUrl: 'public/images/pages/products/product-4.jpg', price: '30000', name: 'CALATRAVA', tag: 'New Arrival', tagbg: 1 },
        { imageUrl: 'public/images/pages/products/product-5.jpg', price: '65000', name: 'CALENDARIO ANUAL CHRONOGRAPH', tag: 'New Arrival', tagbg: 1 },
        { imageUrl: 'public/images/pages/products/product-7.jpg', price: '275000', name: 'CESTIAL', tag: 'New Arrival', tagbg: 1 },
      ],

      // Formulario principal
      form: {
        quantity: 1,
        material: null,
        recubrimiento: null,
      },

      // == Carousel options ==
      galleryOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },
      },

      productsOptions: {
        slidesPerView: 4,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          1300: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },
      product:{
        brand:null,
        price:null,
        images:[],
      },
      products:[],
      // == ==
    }
  },
  watch:{
        '$route.params.id':function(){

            this.id = this.$route.params.id;
            this.getRow();

        }
    },
  methods: {
    noPaste(evt){
      event.preventDefault();
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    changeCantidad($type){
      this.form.quantity = parseInt(this.form.quantity);
      if ($type == '-') {
        this.form.quantity = (this.form.quantity > 1) ? --this.form.quantity : 1;
      }
      else{
        this.form.quantity = (this.form.quantity < 100) ? ++this.form.quantity : 100;
      }
    },
    getRow(){
      axios.get("https://new.legacytimepieces.com.mx/api/product/"+this.id).then((response)=>{
        // axios.get(tools.url("/api/product/"+this.id)).then((response)=>{
			    	this.product = response.data.row;
            this.products = response.data.products;
			  }).catch((error)=>{

			  });
    },
    toContact(){
      this.$router.push('/contacto?data='+this.product.name+'- REFERENCE: '+this.product.reference_number);
    },
  },
  mounted(){
    this.id = this.$route.params.id;
      this.getRow();
  }
}
</script>
